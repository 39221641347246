.login-container {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .login-title {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
  }
  
  .login-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  